@import 'style/mixins';

.CalendarItem {
  border-top: $border-normal;

  &__teaser {
    display: grid;
    grid-template: 'date time title location button';
    grid-template-columns: 25fr 25fr 35fr 15fr 16px;
    padding: 2px 0 15px;
    cursor: pointer;

    .no-touchevents &:hover {
      color: var(--color-hover);
    }

    @include tablet {
      grid-template:
        'date title button'
        'time title button'
        'time location button';
      grid-template-columns: 50fr 50fr 16px;
    }

    &__col {
      &__1 {
        grid-area: date;
        padding-right: $padding-horizontal;
      }

      &__2 {
        grid-area: time;
        padding-right: $padding-horizontal;
      }

      &__3 {
        grid-area: title;
        padding-right: $padding-horizontal;
      }

      &__4 {
        grid-area: location;
        padding-right: $padding-horizontal;
      }

      &__5 {
        grid-area: button;
      }
    }

    @include mobile {
      grid-template:
        'date title button'
        'time title button'
        'time location button';
      grid-template-columns: 40fr 50fr 16px;
    }

    @include tiny {
      grid-template-columns: 30fr 50fr 16px;
      font-size: var(--fs-tiny);
      line-height: var(--lh-tiny);
    }

    &__col {
      &__1 {
        grid-area: date;
        padding-right: $padding-horizontal;
      }

      &__2 {
        grid-area: time;
        padding-right: $padding-horizontal;
      }

      &__3 {
        grid-area: title;
        padding-right: $padding-horizontal;
      }

      &__4 {
        grid-area: location;
        padding-right: $padding-horizontal;
      }

      &__5 {
        grid-area: button;
      }
    }

    &__location {
      padding: 0;
      margin: 0;
      color: inherit;
      text-align: left;
      background: none;
      appearance: none;
      border: 0;
      cursor: pointer;
    }

    &__button {
      position: relative;
      top: -6px;
      width: 100%;
      padding: 3px 2px;

      @include tiny {
        top: -3px;
      }

      &--active svg {
        transform: rotate(180deg);
      }

      svg {
        width: 100%;
        fill: var(--color-primary);
        transition: transform 250ms linear;
      }
    }
  }

  &__detail {
    @include text-link;
    overflow: hidden;

    &__grid {
      display: grid;
      grid-template: 'left right button';
      grid-template-columns: 50fr 50fr 16px;
      flex-flow: row nowrap;
      padding-bottom: 15px;

      @include mobile {
        grid-template: 'right button';
        grid-template-columns: 100fr 16px;
      }
    }

    &__left {
      grid-area: left;

      &__figure {
        position: relative;
        width: 35%;
        margin: 0;
        filter: $calendar-item-filter;
        transition: $calendar-item-transition;

        .no-touchevents &:hover {
          filter: none;
        }

        &__ratio {
          height: 0;
          overflow: hidden;
        }

        &__img {
          position: absolute;
          display: block;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    &__right {
      grid-area: right;

      .Heading,
      .Text {
        max-width: 100%;
      }

      .Heading__inner,
      .Text__inner {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
}
