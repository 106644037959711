@font-face {
  font-family: 'Antique Legacy';
  src: url('fonts/Antique-Legacy-Semibold.woff2') format('woff2'),
    url('fonts/Antique-Legacy-Semibold.woff') format('woff');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Antique Legacy';
  src: url('fonts/Antique-Legacy-Semibold-Italic.woff2') format('woff2'),
    url('fonts/Antique-Legacy-Semibold-Italic.woff') format('woff');
  font-style: italic;
  font-weight: 600;
}
