@import 'style/mixins';

.Tags {
  position: relative;

  &__inner {
    padding: 5px $padding-horizontal 0;
  }

  &__list {
    @include text-large-sans;
    display: flex;
    flex-flow: row wrap;
    margin: 0;
    padding: 0;
    list-style: none;

    @include tablet {
      flex-flow: column;
    }

    @include mobile {
      flex-flow: column;
    }

    &__item {
      flex: 0 0 auto;
      cursor: pointer;
      color: var(--color-text);

      &__button {
        padding: 0;
        color: var(--color-text);
        line-height: inherit;
        text-align: left;
        text-transform: uppercase;
        appearance: none;
        background: none;
        border: 0;
        cursor: pointer;

        .no-touchevents &:hover,
        &--active {
          color: var(--color-hover);
        }
      }

      &::after {
        content: ',\00a0';

        @include tablet {
          content: '';
        }

        @include mobile {
          content: '';
        }
      }

      &:last-of-type::after {
        content: '.\00a0';

        @include tablet {
          content: '';
        }

        @include mobile {
          content: '';
        }
      }
    }

    &__reset {
      @include text-large-sans;
      flex: 0 0 auto;

      &__button {
        padding: 0;
        color: var(--color-text);
        line-height: inherit;
        text-transform: uppercase;
        appearance: none;
        background: none;
        border: 0;
        cursor: pointer;

        .no-touchevents &:hover {
          color: var(--color-hover);
        }
      }
    }
  }
}
