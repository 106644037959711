@import '../constants';

@mixin font-family($family) {
  @if $family == serif {
    font-family: Times, 'Times New Roman', serif;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  } @else if $family == sans-serif {
    font-family: 'Antique Legacy', 'Helevetica Neue', Helvetica, Arial,
      sans-serif;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  } @else if $family == monospace {
    font-family: 'Courier New', monospace;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  } @else {
    @warn 'Unknown font-family specified, please check for spelling mistakes';
  }
}

@mixin text-normal-sans {
  @include font-family('sans-serif');
  font-size: var(--fs-normal);
  font-style: normal;
  font-weight: 600;
  line-height: var(--lh-normal);
}

@mixin text-medium-sans {
  @include font-family('sans-serif');
  font-size: var(--fs-medium);
  font-style: normal;
  font-weight: 600;
  line-height: var(--lh-medium);
}

@mixin text-large-sans {
  @include font-family('sans-serif');
  font-size: var(--fs-large);
  font-style: normal;
  font-weight: 600;
  line-height: var(--lh-large);
  text-transform: uppercase;
}

@mixin text-ultra-sans {
  @include font-family('sans-serif');
  font-size: var(--fs-ultra);
  font-style: normal;
  font-weight: 600;
  line-height: var(--lh-ultra);
  text-transform: uppercase;
}

@mixin text-tiny-sans {
  @include font-family('sans-serif');
  font-size: $fs-tiny;
  font-style: normal;
  font-weight: 600;
  line-height: $lh-tiny;
  text-transform: uppercase;
}

@mixin text-selection {
  ::-moz-selection {
    color: $color-white;
    background: rgba(0, 0, 0, 0.99);
  }

  ::selection {
    color: $color-white;
    background: rgba(0, 0, 0, 0.99);
  }
}

@mixin navigation-link {
  a {
    color: inherit;

    .no-touchevents &:hover {
      color: var(--color-hover);
    }
  }
}

@mixin text-link {
  a {
    font-style: italic;
    color: inherit;

    .no-touchevents &:hover {
      color: var(--color-hover);
    }
  }
}

@mixin text-invert {
  background: inherit;
  background-clip: text;
  color: transparent;
  filter: invert(1) grayscale(1) contrast(4);
}

p {
  margin: 0 0 1em;

  &:last-child {
    margin-bottom: 0;
  }
}

a {
  color: inherit;
  text-decoration: none;
}
